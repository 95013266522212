<template>
  <v-row justify="center">
    <v-col cols="12" md="10">
      <v-card shaped>
        <v-toolbar color="indigo" dark flat>
          <v-toolbar-title>{{editMode ? 'Editing' : ''}} {{editedItem.maker}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="editable" icon @click="editMode = !editMode">
            <v-icon>{{editMode ? 'mdi-pencil-off' : 'mdi-pencil'}}</v-icon>
          </v-btn>
        </v-toolbar>
        
        <v-alert v-if="changes.length > 0"
          class="my-2 px-4"
          dense 
          border="left"
          type="info"
        >There are unsaved changes.</v-alert>
        
        <v-card-text>
          <v-row class="align-center" v-if="editedItem.distiller || editMode">
            <v-col cols="3">
              Distillery
            </v-col>
            <v-col cols="9">
              <v-text-field :readonly="!editMode" placeholder="Distillery" v-model="editedItem.distiller"></v-text-field>
            </v-col>
          </v-row>
          
          <v-row class="justify-center">
            <v-col cols="3">
              Brand or Name
            </v-col>
            <v-col cols="9">
              <v-text-field :color="color" :readonly="!editMode" placeholder="Brand or Name" v-model="editedItem.maker"></v-text-field>
            </v-col>
          </v-row>
          
          <v-row class="align-center">
            <v-col cols="3">
              Type
            </v-col>
            <v-col cols="9">
              <ingredient-type-picker 
                :readonly="!editMode" 
                v-bind:type.sync="editedItem.type"
              ></ingredient-type-picker>
            </v-col>
          </v-row>
          
          <v-row class="align-center" v-if="editedItem.subtype || editMode">
            <v-col cols="3">
              Subtype
            </v-col>
            <v-col cols="9">
              <ingredient-subtype-picker 
                :readonly="!editMode"
                :type="editedItem.type" 
                v-bind:subtype.sync="editedItem.subtype"
              ></ingredient-subtype-picker>
            </v-col>
          </v-row>
          
          <v-row class="align-center" v-if="editedItem.variant || editMode">
            <v-col cols="3">
              Variant
            </v-col>
            <v-col cols="9">
              <ingredient-variant-picker
                :readonly="!editMode"
                :subtype="editedItem.subtype"
                v-bind:variant.sync="editedItem.variant"
              ></ingredient-variant-picker>
            </v-col>
          </v-row>
          
          <v-row class="align-center" v-if="editedItem.subvariant || editMode">
            <v-col cols="3">
              Subvariant
            </v-col>
            <v-col cols="9">
              <ingredient-subvariant-picker
                :readonly="!editMode"
                :variant="editedItem.variant"
                v-bind:subvariant.sync="editedItem.subvariant"
              ></ingredient-subvariant-picker>
            </v-col>
          </v-row>
          
          <v-row class="align-center">
            <v-col cols="3">
              Item Size
            </v-col>
            
            <v-col cols="9">
              <qty-measurement-picker
                :readonly="!editMode"
                :qty.sync="editedItem.originalQty"
                :measurement.sync="editedItem.measurement"
              >  
              </qty-measurement-picker>
            </v-col>
          </v-row>

          <v-row class="align-center">
            <v-col cols="3">
              Item Link
            </v-col>
            
            <v-col cols="9">
              <v-text-field
                :readonly="!editMode"
                v-model="editedItem.link"
                placeholder="Product Link"
              >  
              </v-text-field>
            </v-col>
          </v-row>
          
          <v-row class="align-center">
            <v-col cols="3">
              Amount Remaining
            </v-col>
            
            <v-col cols="9">
              <qty-measurement-picker
                :readonly="!editMode"
                :qty.sync="editedItem.qty"
                :measurement.sync="editedItem.measurement"
              >  
              </qty-measurement-picker>
            </v-col>
          </v-row>
            
        </v-card-text>
        <v-card-actions v-if="editMode">
          
          <v-dialog v-model="deleteConfirmation" max-width="350"
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="0" rounded color="error"
                v-bind="attrs"
                v-on="on"
              >
                Delete
                <v-icon right>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar dense flat color="red" dark>
                <v-spacer></v-spacer>
                ⚠️ Delete {{editedItem.maker}} - {{editedItem.type}} ⚠️
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text class="my-2">
                <p>
                WARNING ⚠️: You will be deleting {{editedItem.maker}}. This is PERMANENT and is NOT UNDOABLE. 
                If you would you to continue, click "Delete" below.
                </p>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn elevation="0" rounded color="error" @click="deleteInventoryItem"
                >
                  Delete
                  <v-icon right>mdi-delete-forever</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          <v-spacer></v-spacer>
          <v-btn elevation="0" rounded @click="cancelEditInventoryItem">
            Cancel
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn elevation="0" rounded color="success" @click="updateInventoryItem">
            Save
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import { DB } from '../firebase/db.js'
  import { mapState } from 'vuex'
  
  export default {
    name: 'EditInventory', 
    data: () => ({
      deleteConfirmation: false, 
      
      inventoryItem: {
        maker: ''
      }, 
      editedItem: {
        
      },
      editMode: false,
      loading: false,
    }), 
    mounted(){
      let id = this.$route.params.id
      var foundItem = this.$store.getters.getInventoryById(id);
      
      if(this.$route.path.indexOf('/edit') >= 0){
        this.editMode = true;
      }

      // If loading from scratch
      if(foundItem == undefined){
        let ref = DB.collection('ingredients').doc(id);
        ref.get()
          .then( document => {
            if(document.exists){
              var doc = document.data();
              if(doc.ownerId == this.user.data.id){
                this.inventoryItem = {id: document.id, ...document.data()}
                this.editedItem = {id: document.id, ...document.data()}
              }
            }
          })
      } else { 
        this.inventoryItem = foundItem;
        this.editedItem = {...this.inventoryItem}
      }
    },
    created(){

    },
    computed: {
      ...mapState({
        storeInventory: 'inventory', 
        user: 'user'
      }), 
      editable(){
        return this.inventoryItem.ownerId == this.user.data.id;
      }, 
      color(){
        return this.getIngredientColor(this.inventoryItem.type);
      }, 
      changes(){
        var changes = []
        
        for(const [key, value] of Object.entries(this.editedItem)){
          if(typeof this.inventoryItem[key] != Object){
            if(this.inventoryItem[key] != this.editedItem[key]){
              var change = {};
              change[key] = value;
              
              changes.push(change)
            }
          }
        }
        
        return changes;
      }
    },
    methods: {
      cancelEditInventoryItem(){
        // Go through original item, and set editedItem = originalItem
        
        for(const [key, value] of Object.entries(this.inventoryItem)){
          
          if(this.editedItem[key] != this.inventoryItem[key]){
            this.editedItem[key] = this.inventoryItem[key]
          }
          
        }
        
        this.editMode = false;
      },
      deleteInventoryItem(){
        // Add two step verification, once button is clicked then do popup 
        
        this.$store.dispatch('deleteIngredient', {id: this.$route.params.id})
        this.$emit('showAlert', {text: 'This item has been deleted!', type: 'error', top: true});
        
        setTimeout(() => {
          this.$router.push('/inventory');
        }, 2000);
      },
      updateInventoryItem(){
        let id = this.$route.params.id;
        
        // Iterate over all the keys in the objects, and just update whatever changed from the original 
        if(this.changes.length == 0){
          this.$emit('showAlert', {text: 'No changes have been made. You\'re good 😎', type: 'info', top: true});
          return;
        } else {
          
          var changesMade = {}
          
          this.changes.forEach(change => {
            for(const [key, value] of Object.entries(change)){
              changesMade[key] = value;
            }
          })
          
          this.$store.dispatch('updateIngredient', {id: this.$route.params.id, changes: changesMade})
          this.$emit('showAlert', {text: 'Success! The ingredient has been updated 🎉. Going back to ingredients.', type: 'success', top: true});
          
          setTimeout(() => {
            this.editMode = false;
            this.$router.push('/inventory');
          }, 2000);
        }
      },
      setInventory(inventoryItem){
        this.inventoryItem = inventoryItem
      },
      getIngredientColor(ingredientType){
        let type = ingredientType == undefined ? '' : ingredientType.toLowerCase(); 
        
        switch(type){ 
          case 'whiskey':
            return 'yellow darken-3';
            break;
          case 'whisky': 
            return 'yellow darken-3';
            break;
          case 'brandy': 
            return 'red';
            break;
          case 'vodka': 
            return 'brown';
            break;
          case 'rum':
            return 'deep-orange';
            break;
          case 'gin':
            return 'green';
            break;
          case 'tequila': 
            return 'light-green';
            break;
          case 'liqueur': 
            return 'teal'; 
            break;
          default: 
            return 'blue-grey';
        }
      }
    }
  }
</script>